import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';

import PageWrapper from 'components/page-wrapper';
import Head from 'components/head';
import Box from 'components/box';
import Slider from 'containers/slider';
import PreLoad from 'components/pre-load';

const TypePage = ({ data }) => (
  <Location>
    {({ location }) => (
      <PageWrapper bg="bg-type select-typeBright h-screen">
        <Head pageTitle="Women of Type" />
        <Box width="max-w-full p-9 sm:p-14">
          <Box width="p-6 sm:p-12 xl:px-32" flex="flex flex-col h-full">
            <Slider
              location={location}
              slides={data.contentfulTypePage.slides}
            />
          </Box>
        </Box>
        <div className="absolute" style={{ opacity: 0, left: '100vw' }}>
          {data.allContentfulTypeSlide.edges.map(({ node }) => (
            <PreLoad
              key={node.title}
              styleString={node.fontFile}
              text={node.text.text}
            />
          ))}
        </div>
      </PageWrapper>
    )}
  </Location>
);

TypePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TypePage;

export const query = graphql`
  query TypePageQuery {
    contentfulTypePage {
      slides {
        id
        title
        titleUrl
        designer
        designerUrl
        text {
          id
          text
        }
        fontFile
      }
    }
    allContentfulTypeSlide {
      edges {
        node {
          id
          title
          titleUrl
          designer
          designerUrl
          text {
            id
            text
          }
          fontFile
        }
      }
    }
  }
`;
