import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  base:
    'w-full hidden sm:block px-15 sm:px-20 md:px-30 mx-auto fixed left-0 right-0',
});

export const Group = nanostyled('div', {
  base: 'flex m-px -mr-px border-black border-solid border',
});

export const Item = nanostyled('div', {
  border: '',
  active: 'bg-infoBright',
  base:
    'hover:bg-info flex justify-center w-full border-solid border-black p-2 text-black text-sm uppercase no-underline',
});
