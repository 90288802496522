import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import posed, { PoseGroup } from 'react-pose';

import Box from 'components/box';
import Type from 'components/type';
import ScalingType from 'components/scaling-type';
import ParfumerieDesktop from 'images/parfumerie-desktop.svg';
import ParfumerieMobile from 'images/parfumerie-mobile.svg';
import BalconySvg from 'images/balcony.svg';

import { Container } from './slider.css';

const Animated = posed.div({
  preEnterToRight: {
    x: 'calc(-100vw + 3.5rem)',
  },
  preEnterToLeft: {
    x: 'calc(100vw - 3.5rem)',
  },
  enterToLeft: {
    x: '0vw',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
  enterToRight: {
    x: '0vw',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
  exitToRight: {
    x: 'calc(100vw - 3.5rem)',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
  exitToLeft: {
    x: 'calc(-100vw + 3rem)',
    transition: {
      duration: 600,
      ease: 'easeInOut',
    },
  },
});

const getDirection = (side, pose) => {
  switch (side) {
    case 'prev':
      return pose + 'ToRight';
    case 'next':
      return pose + 'ToLeft';
    default:
      return null;
  }
};

class Slider extends PureComponent {
  componentDidMount() {
    // check for query state
    // TODO check if we have already changed slides prev and the activeslide state is set
    const { location, activeSlide, slides, goToSlide } = this.props;
    // location &&
    //   location.hash &&
    //   goToSlide(location.hash.replace('#', ''), 'next');
    // listener for keys
    document.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    const {
      slides,
      activeSlide,
      slideDirection,
      prevSlide,
      nextSlide,
      goToSlide,
      location,
    } = this.props;

    if (key === 'ArrowLeft') {
      activeSlide > 0
        ? this.update(activeSlide, prevSlide, -1)
        : this.goToUpdate(activeSlide, slides.length - 1, goToSlide);
    }
    if (key === 'ArrowRight') {
      activeSlide < slides.length - 1
        ? this.update(activeSlide, nextSlide, +1)
        : this.goToUpdate(activeSlide, 0, goToSlide);
    }
    // if (key === 'Escape') {
    //   this.props.open && this.props.hideModal();
    //
  };

  update(index, action, num) {
    // if (typeof window !== 'undefined') {
    //   const newurl =
    //     window.location.protocol +
    //     '//' +
    //     window.location.host +
    //     window.location.pathname +
    //     '#' +
    //     (index + num);
    //   window.history.pushState({ path: newurl }, '', newurl);
    // }
    action();
  }

  goToUpdate(index, target, action) {
    // if (typeof window !== 'undefined') {
    //   const newurl =
    //     window.location.protocol +
    //     '//' +
    //     window.location.host +
    //     window.location.pathname +
    //     '#' +
    //     target;
    //   window.history.pushState({ path: newurl }, '', newurl);
    // }
    const direction = target < index ? 'next' : 'prev';
    action(target, direction);
  }

  render() {
    const {
      slides,
      activeSlide,
      slideDirection,
      prevSlide,
      nextSlide,
      goToSlide,
      location,
    } = this.props;

    return (
      <>
        {/*<Type as="p" spacing="mb-10 relative max-w-2xl">
          Each typeface has its own character (pun intended!). This section
          gathers a selection of great typefaces designed by women from all over
          the world. But what does each typeface aim to express? Let’s let them
          speak for themselves!
        </Type>*/}
        <Box className="parent" width="h-full w-full flex items-center">
          <PoseGroup
            preEnterPose={getDirection(slideDirection, 'preEnter')}
            enterPose={getDirection(slideDirection, 'enter')}
            exitPose={getDirection(slideDirection, 'exit')}
            enterAfterExit="false"
          >
            <Animated
              className="w-full h-full border-box"
              key={slugify(slides[activeSlide].title, {
                lower: true,
              })}
            >
              {location &&
                slides[activeSlide].id ===
                  'c21914d1-b154-535e-9a4c-8edd4a1472d2' && (
                  <div className="w-full h-full flex items-center">
                    <ParfumerieDesktop className="hidden lg:block w-full" />
                    <ParfumerieMobile className="lg:hidden w-full" />
                  </div>
                )}
              {location &&
                slides[activeSlide].id ===
                  'a3150292-a1e0-55a0-bb57-c7d02a8ce52f' && (
                  <div className="w-full h-full flex items-center relative">
                    <BalconySvg className="absolute top-0 right-0 bottom-0 left-0 max-w-full max-h-full w-full h-full" />
                  </div>
                )}
              {location &&
                slides[activeSlide].id !==
                  'c21914d1-b154-535e-9a4c-8edd4a1472d2' &&
                slides[activeSlide].id !==
                  'a3150292-a1e0-55a0-bb57-c7d02a8ce52f' && (
                  <ScalingType
                    location={location}
                    slug={slugify(slides[activeSlide].title, {
                      lower: true,
                    })}
                    styleString={slides[activeSlide].fontFile}
                  >
                    {slides[activeSlide].id ===
                      'b0169607-290f-5a8f-a7bc-547802a49c4e' && (
                      <div className="malee-slide">
                        <p className="font-maleesans">
                          Each typeface has its own character (pun intended!).{' '}
                        </p>
                        <p>
                          This section gathers a selection of great typefaces
                          designed by women from all over the world.{' '}
                        </p>
                        <p className="font-maleesans">
                          But what does each typeface aim to express?{' '}
                        </p>
                        <p>Let’s let them speak for themselves!</p>
                      </div>
                    )}
                    {slides[activeSlide].id ===
                      '29db8ac9-8962-5369-9352-03a674b70271' && (
                      <div>
                        <p>𛈖𛋉𛅰𛉊</p>
                        <p>𛆤𛅽𛊓𛆁</p>
                      </div>
                    )}
                    {slides[activeSlide].id !==
                      '29db8ac9-8962-5369-9352-03a674b70271' &&
                      slides[activeSlide].id !==
                        'b0169607-290f-5a8f-a7bc-547802a49c4e' && (
                        <p>{slides[activeSlide].text.text}</p>
                      )}
                  </ScalingType>
                )}
            </Animated>
          </PoseGroup>
        </Box>
        <Type as="p" spacing="mt-10 mb-0 relative max-w-2xl">
          {slides[activeSlide].titleUrl ? (
            <a
              className="text-black hover:no-underline"
              href={slides[activeSlide].titleUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{slides[activeSlide].title}</span>
            </a>
          ) : (
            <span>{slides[activeSlide].title}</span>
          )}{' '}
          typeface. Designed by{' '}
          {slides[activeSlide].designerUrl ? (
            <a
              className="text-black hover:no-underline"
              href={slides[activeSlide].designerUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {slides[activeSlide].designer}
            </a>
          ) : (
            <span>{slides[activeSlide].designer}</span>
          )}
          .
        </Type>

        <Box
          flex="flex"
          width="w-full h-9 sm:h-14 fixed bottom-0 left-0 right-0 px-9 sm:px-14"
        >
          {activeSlide > 0 ? (
            <Box
              width="w-full border-t border-r border-solid bg-typeBright hover:bg-type uppercase"
              flex="flex items-center justify-center"
            >
              <button
                className="font-malee appearance-none border-none bg-transparent no-underline text-black w-full h-full text-sm sm:text-base uppercase"
                onClick={() => this.update(activeSlide, prevSlide, -1)}
              >
                previous
              </button>
            </Box>
          ) : (
            <Box
              width="w-full border-t border-r border-solid bg-type uppercase font-malee text-sm sm:text-base"
              flex="flex items-center justify-center"
            />
          )}
          {activeSlide < slides.length - 1 ? (
            <Box
              width="w-full border-t border-solid bg-typeBright hover:bg-type uppercase"
              flex="flex items-center justify-center"
            >
              <button
                className="font-malee appearance-none bg-transparent border-none no-underline text-black w-full h-full text-sm sm:text-base uppercase"
                onClick={() => this.update(activeSlide, nextSlide, 1)}
              >
                next
              </button>
            </Box>
          ) : (
            <Box
              width="w-full border-t border-solid uppercase bg-typeBright hover:bg-type"
              flex="flex items-center justify-center"
            >
              <button
                className="font-malee appearance-none bg-transparent border-none no-underline text-black w-full h-full text-sm sm:text-base uppercase"
                onClick={() => this.goToUpdate(activeSlide, 0, goToSlide)}
              >
                return to beginning
              </button>
            </Box>
          )}
        </Box>
      </>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.array,
  activeSlide: PropTypes.number,
  slideDirection: PropTypes.string,
  prevSlide: PropTypes.func,
  nextSlide: PropTypes.func,
  goToSlide: PropTypes.func,
  location: PropTypes.object,
};

export default Slider;
