import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

import Logo from 'images/malee-logo.svg';

export const Container = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  box-sizing: initial;
  ${MEDIA.XLPHONE`
    height: 2.25rem;
  `}

  a {
    color: #000;
    transition: opacity 0.2s ease;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Malee Serif', serif;
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
  ${MEDIA.XLPHONE`
    font-size: 6vw;
    padding: 1rem 0;
  `}
`;

export const StyledSvg = styled.div`
  display: flex;
  svg {
    max-width: 80vw;
    max-height: 2rem;
  }
`;
