import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Location } from '@reach/router';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import SplitBox from 'components/split-box';
import Type from 'components/type';

const Index = ({ data }) => (
  <Location>
    {({ location }) => (
      <PageWrapper withAlert>
        <SplitBox
          location={location}
          background="bg-typeBright select-type"
          side="left"
        >
          <Box width="max-w-sm p-6 mb-14 md:mb-4 sm:p-8">
            <Type align="text-center" size="large" font="serif">
              {documentToReactComponents(
                data.contentfulSplashPage.leftColumn.json
              )}
            </Type>
          </Box>
        </SplitBox>
        <SplitBox
          location={location}
          background="bg-infoBright select-info"
          side="right"
        >
          <Box width="max-w-sm p-6 mb-14 md:mb-4 sm:p-8">
            <Type align="text-center" size="large">
              {documentToReactComponents(
                data.contentfulSplashPage.rightColumn.json
              )}
            </Type>
          </Box>
        </SplitBox>
      </PageWrapper>
    )}
  </Location>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    contentfulSplashPage {
      id
      leftColumn {
        json
      }
      rightColumn {
        json
      }
    }
  }
`;
