import React from 'react';
import PropTypes from 'prop-types';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import { Portal } from 'react-portal';
import AwsS3 from '@uppy/aws-s3';
import { join, map, filter } from 'lodash';

import { Container } from './uppy-modal.css';
import FloatingInput from 'components/floating-input';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

class UppyModal extends React.Component {
  constructor(props) {
    super(props);
    this.uppy = Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: this.props.max,
        allowedFileTypes: this.props.filetype,
      },
    });
    this.uppy.use(AwsS3, { companionUrl: '/.netlify/functions/companion' });

    this.uppy.on('complete', result => {
      console.log('successful files:', result.successful);
      console.log('failed files:', result.failed);
      let currentState = this.uppy.getState();
      console.log(join(map(currentState.files, item => item.uploadURL)));
      // results.successful.map(item => item.uploadURL);
      this.props.setFieldValue(
        this.props.nameUrl,
        map(currentState.files, item => item.uploadURL)
      );
      this.props.setFieldValue(
        this.props.name,
        join(
          map(filter(currentState.files, 'uploadURL'), item => item.name),
          ' + '
        )
      );
      console.log(this.uppy.getState());
    });

    this.uppy.on('cancel-all', result => {
      let currentState = this.uppy.getState();
      console.log(currentState);

      if (typeof this.props.setFieldValue === 'function') {
        this.props.setFieldValue(this.props.nameUrl, '');
        this.props.setFieldValue(this.props.name, '');
      }
    });

    this.state = {
      modalOpen: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.disableScrolling = this.disableScrolling.bind(this);
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  handleOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  handleReset(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.uppy.cancelAll();
  }

  disableScrolling(modalOpen) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    if (typeof document !== 'undefined') {
      this.disableScrolling(this.state.modalOpen);
    }

    const {
      name,
      nameUrl,
      type,
      pos,
      placeholder,
      onChange,
      onBlur,
      value,
      valueUrl,
      error,
      touched,
      setFieldValue,
    } = this.props;

    return (
      <>
        {this.state.modalOpen && (
          <Portal>
            <DashboardModal
              uppy={this.uppy}
              closeModalOnClickOutside
              open={this.state.modalOpen}
              onRequestClose={this.handleClose}
              proudlyDisplayPoweredByUppy={false}
              disablePageScrollWhenModalOpen={false}
            />
          </Portal>
        )}

        <label htmlFor={nameUrl} className="hidden">
          {`Don’t fill this out (${nameUrl}): `}
          <input
            id={nameUrl}
            name={nameUrl}
            value={valueUrl}
            onChange={onChange}
          />
        </label>

        <div
          className={`w-full relative ${pos === 'left' &&
            'md:w-1/2 md:pr-3'} ${pos === 'right' && 'md:w-1/2 md:pl-4'}`}
          role="button"
          tabIndex="0"
          onClick={this.handleOpen}
          onKeyPress={this.handleOpen}
        >
          <FloatingInput
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error}
            touched={touched}
            icons="no-check"
            autocomplete={`${name}-upload`}
          />
          {value && (
            <button
              style={{ fontSize: '1em' }}
              className={`font-malee absolute bg-transparent border-none right-0 top-0 my-4 mx-2 ${pos ===
                'left' && 'md:mr-4'} hover:underline`}
              onClick={e => this.handleReset(e)}
            >
              reset
            </button>
          )}
        </div>
      </>
    );
  }
}

UppyModal.propTypes = {
  width: PropTypes.string,
  flex: PropTypes.string,
};

export default UppyModal;
