import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Close, Submit } from './results.css';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import { scroller as scroll } from 'react-scroll';

import '@reach/dialog/styles.css';

// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
export default class Results extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.openResults && this.props.hideResults();
    }
  };

  disableScrolling(openResults) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (openResults) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    const {
      children,
      openResults,
      showResults,
      hideResults,
      validateForm,
      setFieldTouched,
      scrollTo,
      isValid,
    } = this.props;

    if (typeof document !== 'undefined') {
      this.disableScrolling(openResults);
    }

    return (
      <>
        {isValid ? (
          <Submit
            type="button"
            onClick={() => {
              showResults();
              scroll.scrollTo(0);
            }}
          >
            Review
          </Submit>
        ) : (
          <Submit
            type="button"
            onClick={() =>
              validateForm().then(errors => {
                setFieldTouched('type', true);
                setFieldTouched('firstName', true);
                setFieldTouched('lastName', true);
                setFieldTouched('dateOfBirth', true);
                setFieldTouched('nationality', true);
                setFieldTouched('address', true);
                setFieldTouched('email', true);
                setFieldTouched('phone', true);
                setFieldTouched('portraitPicture', true);
                setFieldTouched('backgroundEssay', true);
                setFieldTouched('cv', true);
                setFieldTouched('facebook', true);
                setFieldTouched('twitter', true);
                setFieldTouched('instagram', true);
                setFieldTouched('linkedin', true);
                setFieldTouched('portfolioFile', true);
                setFieldTouched('recLetters', true);
                setFieldTouched('plan', true);
                setFieldTouched('idealCandidate', true);
                scroll.scrollTo('error', {
                  duration: 250,
                  smooth: true,
                  isDynamic: true,
                  offset: -200,
                });
              })
            }
          >
            Review
          </Submit>
        )}

        <Dialog isOpen={openResults && isValid}>
          {/*<Close onClick={hideResults}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </Close>*/}
          {children}
          <div className="text-center mx-auto">
            <Button type="button" onClick={hideResults}>
              Edit Application
            </Button>
          </div>
        </Dialog>
      </>
    );
  }
}

Results.propTypes = {
  children: PropTypes.node,
  openResults: PropTypes.bool.isRequired,
  showResults: PropTypes.func,
  hideResults: PropTypes.func,
};
