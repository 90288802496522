import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import slugify from 'slugify';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';

const Application = ({ data }) => (
  <PageWrapper bg="bg-info select-infoBright" withAlert>
    <Head pageTitle="Application" />
    <Box
      width="max-w-full pt-9 sm:pt-14 px-9 sm:px-14"
      flex="flex flex-col items-center"
    >
      <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
        <Type align="text-center" size="large">
          {documentToReactComponents(
            data.contentfulApplicationPage.headline.json
          )}
        </Type>
        <Divider spacing="mt-10 mb-5" />
      </Box>
      <Box width="px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
        <Type as="h3" align="text-center" size="large">
          Application deadline: {data.contentfulApplicationPage.deadline}
        </Type>
        <Divider spacing="mt-5 mb-10" />
        <Type as="h3" align="text-center" size="large">
          Application Requirements
        </Type>
        <Type
          as="ul"
          spacing="list-disc col-count-1 md:col-count-2 col-gap-xl max-w-md ml-5 md:mx-auto mt-10 mb-14"
        >
          {data.contentfulApplicationPage.requirements.map(item => (
            <Type
              as="li"
              spacing="pl-2 mb-1 li"
              key={slugify(item, { lower: true })}
            >
              {item}
            </Type>
          ))}
        </Type>

        <Box flex="flex justify-center">
          <Link
            to="application-form"
            state={{ direction: 'toLeft' }}
            className="text-black no-underline mx-auto"
          >
            <div className="border border-solid border-black bg-infoBright hover:bg-info uppercase px-8 py-4">
              Start Application
            </div>
          </Link>
        </Box>
        {/* <div className="sm:hidden ">
          <AppReminder />
        </div> */}
      </Box>
    </Box>
  </PageWrapper>
);

Application.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Application;

export const query = graphql`
  query ApplicationQuery {
    contentfulApplicationPage {
      id
      headline {
        id
        json
      }
      deadline
      requirements
    }
  }
`;
