import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './type.css';

const getSize = size => {
  switch (size) {
    case 'small':
      return 'font-maleesans text-sm lg:text-base xl:text-lg leading-snug';
    case 'small-serif':
      return 'font-malee text-sm lg:text-base xl:text-lg leading-snug';
    case 'large':
      return 'font-malee text-xl sm:text-2xl md:text-xl lg:text-2xl xl:text-3xl leading-snug';
    default:
      return 'font-maleesans text-sm lg:text-base xl:text-lg leading-snug';
  }
};

const Type = ({ id, children, align, size, font, as, spacing }) => {
  return (
    <Text
      id={id}
      as={as}
      align={align}
      size={getSize(size)}
      spacing={spacing}
      font={font}
    >
      {children}
    </Text>
  );
};

Type.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  as: PropTypes.string,
  align: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.string,
  spacing: PropTypes.string,
};

export default Type;

// export const Text = styled.div`
//   /* display: block; */
//   font-size: ${({ size }) => () => {
//     switch (size) {
//       case 'large':
//         return '1.5rem';
//       default:
//         return '1rem';
//     }
//   }};
//   line-height: 1.4;
//   ${({ align }) => align && `text-align: ${align}`};
//   ${({ font }) =>
//     font && font === 'serif' && 'font-family: "Malee Serif", serif;'};
//   ${tw`${props => props.tail}`};
//   a {
//     color: inherit;
//   }

//   h2 {
//     ${tw`mb-8`};
//   }

//   h3 {
//     ${tw`mb-4`};
//   }

//   ul {
//     padding: 0 4rem;
//     column-count: 2;
//     column-gap: 4rem;
//     list-style-type: disc;
//     margin-left: 1em;
//     margin-bottom: 6rem;

//     li {
//       margin-bottom: 0.25em;
//     }
//     /* li::before {
//       content: "●";
//       padding-right: 1em;
//     } */
//   }

//   ${MEDIA.DESKTOP`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.25rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

//   ${MEDIA.TABLET`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.5rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

//   ${MEDIA.XLPHONE`
//     font-size: ${({ size }) => () => {
//       switch (size) {
//         case 'large':
//           return '1.25rem';
//         default:
//           return '0.875rem';
//       }
//     }};
//   `}

// `;
