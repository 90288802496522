import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Persist } from 'formik-persist';
import * as Yup from 'yup';
import { Element } from 'react-scroll';
import { Portal } from 'react-portal';
import { navigate } from 'gatsby';

import IOChangeStep from 'components/io-change-step';
import Results from 'containers/results';
import FloatingInput from 'components/floating-input';
import FloatingTextarea from 'components/floating-textarea';
import Type from 'components/type';
import Box from 'components/box';
import Divider from 'components/divider';
import UppyModal from 'components/uppy-modal';
import Radio from 'components/radio';

import {
  Form,
  Modal,
  ModalButton,
  Circle,
  Button,
  Submit,
  Group,
} from './app-form.css';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class AppForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  sendToNetlify = values => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'application2021', ...values }),
    })
      // .then(this.handleSuccess)
      .catch(error => alert(error));
    // event.preventDefault();
  };

  handleSuccess = (values, { resetForm }) => {
    this.sendToNetlify(values);
    resetForm();
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
    navigate('/', { state: { direction: 'toBoth' } });
  };

  render() {
    return (
      <Formik
        initialValues={{
          type: '',
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          nationality: '',
          address: '',
          email: '',
          phone: '',
          portraitPicture: '',
          portraitPictureUrl: '',
          pronouns: '',
          appliedBefore: '',
          autobiographicalStatement: '',
          cv: '',
          cvUrl: '',
          facebook: '',
          twitter: '',
          instagram: '',
          linkedin: '',
          portfolioFile: '',
          portfolioFileUrl: '',
          portfolioUrl: '',
          recLetters: '',
          recLettesrUrl: '',
          plan: '',
          interest: '',
          idealCandidate: '',
        }}
        // onSubmit={(event, values) => console.log(event, values)}
        onSubmit={this.handleSuccess}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          type: Yup.string().required('A purpose is required'),
          firstName: Yup.string().required('Your first name is required'),
          lastName: Yup.string().required('Your last name is required'),
          dateOfBirth: Yup.string().required('Your date of birth is required'),
          nationality: Yup.string().required('Your nationality is required'),
          address: Yup.string().required('Your address is required'),
          email: Yup.string()
            .email()
            .required('Your email address is required'),
          phone: Yup.string().required('Your phone number is required'),
          portraitPicture: Yup.string().required(
            'Your portrait picture is required'
          ),
          pronouns: Yup.string().required('Your pronouns are required'),
          appliedBefore: Yup.string().required(
            'Indicating if you have applied before is required'
          ),
          autobiographicalStatement: Yup.string().required(
            'Your autobiographical statement is required'
          ),
          cv: Yup.string().required('Your cv is required'),
          facebook: Yup.string(),
          twitter: Yup.string(),
          instagram: Yup.string(),
          linkedin: Yup.string(),
          portfolioFile: Yup.string().required('Your portfolio is required'),
          portfolioUrl: Yup.string(),
          recLetters: Yup.string().required(
            'At least one letter of recommendation is required'
          ),
          interest: Yup.string()
            .required('The "Journey and Interest" essay is required')
            .test(
              'interest-word-count',
              'Your "Journey and Interest" essay is over 150 words',
              value => {
                if (value && value.split(' ').length <= 150) return true;
              }
            ),
          plan: Yup.string()
            .required('The "Financial Need" essay is required')
            .test(
              'plan-word-count',
              'Your "Financial Need" essay is over 150 words',
              value => {
                if (value && value.split(' ').length <= 150) return true;
              }
            ),
          idealCandidate: Yup.string()
            .required('The "Next Recipient" essay is required')
            .test(
              'candidate-word-count',
              'Your "Next Recipient" essay is over 150 words',
              value => {
                if (value && value.split(' ').length <= 150) return true;
              }
            ),
        })}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          validateForm,
          resetForm,
          isValid,
        }) => (
          <Form
            name="application2021"
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="bot"
            // overlay={this.state.showModal}
            // onClick={this.closeModal}
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label>
                Don’t fill this out:{' '}
                <input name="bot" onChange={this.handleInputChange} />
              </label>
            </p>
            <Type spacing="flex flex-col">
              <IOChangeStep section="step1" />
              <Element name="step1">
                <Type as="h3" align="text-center" size="large" spacing="mb-4">
                  Scholarship purpose
                </Type>
              </Element>
              <Type align="mx-auto -mt-4 mb-4 text-center">
                {touched.type && errors.type && (
                  <Element name="error">{errors.type}</Element>
                )}
              </Type>

              <Type align="text-center" spacing="mb-4">
                Please indicate where the scholarship funds will be allocated
                should you be the elected recipient*
              </Type>
              <Group>
                <Radio
                  htmlFor="type-program"
                  name="type"
                  value="An educational type-design program"
                  text="An educational type-design program"
                  id="type-program"
                  pos="left"
                  checkAgainst={values.type}
                  setFieldValue={setFieldValue}
                />
                <Radio
                  htmlFor="self-initiated"
                  name="type"
                  value="The development of a self-initiated type-design project"
                  text="The development of a self-initiated type-design project"
                  id="self-initiated"
                  pos="right"
                  checkAgainst={values.type}
                  setFieldValue={setFieldValue}
                />
              </Group>
              <Divider spacing="mt-10 mb-14" />
              <IOChangeStep section="step2" />
              <Element name="step2">
                <Type as="h3" align="text-center" size="large" spacing="mb-4">
                  Personal information
                </Type>
              </Element>
              <Type align="mx-auto -mt-4 mb-6 text-center">
                {touched.firstName && errors.firstName && (
                  <Element name="error">{errors.firstName}</Element>
                )}
                {touched.lastName && errors.lastName && (
                  <Element name="error">{errors.lastName}</Element>
                )}
                {touched.dateOfBirth && errors.dateOfBirth && (
                  <Element name="error">{errors.dateOfBirth}</Element>
                )}
                {touched.nationality && errors.nationality && (
                  <Element name="error">{errors.nationality}</Element>
                )}
                {touched.address && errors.address && (
                  <Element name="error">{errors.address}</Element>
                )}
                {touched.email && errors.email && (
                  <Element name="error">{errors.email}</Element>
                )}
                {touched.portraitPicture && errors.portraitPicture && (
                  <Element name="error">{errors.portraitPicture}</Element>
                )}
                {touched.pronouns && errors.pronouns && (
                  <Element name="error">{errors.pronouns}</Element>
                )}
              </Type>

              <Group>
                <FloatingInput
                  name="firstName"
                  type="text"
                  pos="left"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  required
                />
                <FloatingInput
                  name="lastName"
                  type="text"
                  pos="right"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  required
                />
                <FloatingInput
                  name="dateOfBirth"
                  type="text"
                  pos="left"
                  placeholder="Date of Birth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateOfBirth}
                  error={errors.dateOfBirth}
                  touched={touched.dateOfBirth}
                  required
                />
                <FloatingInput
                  name="nationality"
                  type="text"
                  pos="right"
                  placeholder="Nationality"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nationality}
                  error={errors.nationality}
                  touched={touched.nationality}
                  required
                />
                <FloatingInput
                  name="address"
                  type="address"
                  pos="left"
                  placeholder="Home Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  error={errors.address}
                  touched={touched.address}
                  required
                />
                <FloatingInput
                  name="email"
                  type="email"
                  pos="right"
                  placeholder="Primary E-mail Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  required
                />
                <FloatingInput
                  name="phone"
                  type="tel"
                  pos="left"
                  placeholder="Phone Number + Country Code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={errors.phone}
                  touched={touched.phone}
                  required
                />
                <UppyModal
                  name="portraitPicture"
                  nameUrl="portraitPictureUrl"
                  type="text"
                  placeholder="Portrait Picture"
                  pos="right"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.portraitPicture}
                  valueUrl={values.portraitPictureUrl}
                  error={errors.portraitPicture}
                  touched={touched.portraitPicture}
                  setFieldValue={setFieldValue}
                  max={1}
                  filetype={['image/*']}
                />
                <FloatingInput
                  name="pronouns"
                  type="text"
                  pos="left"
                  placeholder="Gender Pronouns"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pronouns}
                  error={errors.pronouns}
                  touched={touched.pronouns}
                  required
                />
              </Group>
              <Type align="text-center" spacing="mb-4">
                Please indicate if you have applied to The Malee Scholarship
                before*
              </Type>
              <Group>
                <Radio
                  htmlFor="appliedBefore-yes"
                  name="appliedBefore"
                  value="Yes, I've applied before"
                  text="Yes, I've applied before"
                  id="appliedBefore-yes"
                  pos="left"
                  checkAgainst={values.appliedBefore}
                  setFieldValue={setFieldValue}
                />
                <Radio
                  htmlFor="appliedBefore-no"
                  name="appliedBefore"
                  value="No, this is my first application"
                  text="No, this is my first application"
                  id="appliedBefore-no"
                  pos="right"
                  checkAgainst={values.appliedBefore}
                  setFieldValue={setFieldValue}
                />
              </Group>
              <Divider spacing="mt-10 mb-14" />
              <IOChangeStep section="step3" />
              <Element name="step3">
                <Type as="h3" align="text-center" size="large" spacing="mb-4">
                  Tell us more
                </Type>
              </Element>
              <Type align="mx-auto -mt-4 mb-6 text-center">
                {touched.autobiographicalStatement &&
                  errors.autobiographicalStatement && (
                    <Element name="error">
                      {errors.autobiographicalStatement}
                    </Element>
                  )}
                {touched.interest && errors.interest && (
                  <Element name="error">{errors.interest}</Element>
                )}
                {touched.idealCandidate && errors.idealCandidate && (
                  <Element name="error">{errors.idealCandidate}</Element>
                )}

                {touched.plan && errors.plan && (
                  <Element name="error">{errors.plan}</Element>
                )}
              </Type>
              <Type spacing="mb-4">
                Tell us about yourself. You can write about anything that helps
                us better understand who you are. This could include and is not
                limited to: your family, your cultural background, your
                heritage, your involvement in community, your other passions and
                interests, significant people, places, or events that have
                shaped who you are today.*
              </Type>
              <FloatingTextarea
                name="autobiographicalStatement"
                type="text"
                placeholder="Write your essay here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.autobiographicalStatement}
              />
              <Type spacing="mb-4">
                Tell us about your journey and interest in type design. When did
                it begin or how did it change over time? Who is part of your
                story? We do not expect all applicants to have the same
                background or experiences in type design.*
              </Type>
              <FloatingTextarea
                name="interest"
                type="text"
                placeholder="150 words"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.interest}
                error={errors.interest}
                touched={touched.interest}
                required
              />
              <Type spacing="mb-4">
                Why should you be the next recipient of The Malee Scholarship?*
              </Type>
              <FloatingTextarea
                name="idealCandidate"
                type="text"
                placeholder="150 words"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.idealCandidate}
                error={errors.idealCandidate}
                touched={touched.idealCandidate}
                required
              />
              <Type spacing="mb-4">
                Please explain how the $6,000 USD grant will make a tangible
                impact on your study, practice, or career in type design. The
                money can go towards workshops, post-graduate studies, books,
                software, etc. It can also go towards supporting yourself
                financially while you work on your typeface. The annual
                recipient will be gifted a RoboFont license courtesy of the
                RoboFont team.*
              </Type>
              <FloatingTextarea
                name="plan"
                type="text"
                placeholder="150 words"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.plan}
                error={errors.plan}
                touched={touched.plan}
                required
              />

              <Divider spacing="mt-10 mb-14" />
              <IOChangeStep section="step4" />
              <Type
                id="step4"
                as="h3"
                align="text-center"
                size="large"
                spacing="mb-4"
              >
                CV, Portfolio, and Recommendation letters
              </Type>
              <Type align="mx-auto -mt-4 mb-6 text-center">
                {touched.cv && errors.cv && (
                  <Element name="error">{errors.cv}</Element>
                )}
                {touched.portfolioFile && errors.portfolioFile && (
                  <Element name="error">{errors.portfolioFile}</Element>
                )}
                {touched.portfolioUrl && errors.portfolioUrl && (
                  <Element name="error">{errors.portfolioUrl}</Element>
                )}
                {touched.recLetters && errors.recLetters && (
                  <Element name="error">{errors.recLetters}</Element>
                )}
              </Type>

              <Type as="h4" align="" size="" spacing="mb-4">
                CV*
              </Type>
              <Group>
                <UppyModal
                  name="cv"
                  nameUrl="cvUrl"
                  type="text"
                  placeholder="Upload file (.docx / .pdf)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cv}
                  valueUrl={values.cvUrl}
                  setFieldValue={setFieldValue}
                  max={1}
                  filetype={['.pdf', '.doc', '.docx', '.md', '.rtf']}
                />
              </Group>
              <Type as="h4" align="" size="" spacing="mb-4">
                Social media profiles (optional)
              </Type>
              <Group>
                <FloatingInput
                  name="facebook"
                  type="text"
                  pos="left"
                  placeholder="Facebook"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.facebook}
                />
                <FloatingInput
                  name="twitter"
                  type="text"
                  pos="right"
                  placeholder="Twitter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.twitter}
                />
                <FloatingInput
                  name="instagram"
                  type="text"
                  pos="left"
                  placeholder="Instagram"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.instagram}
                />
                <FloatingInput
                  name="linkedin"
                  type="text"
                  pos="right"
                  placeholder="Linkedin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedin}
                />
              </Group>
              <Type as="h4" align="" size="" spacing="mb-4">
                Portfolio*
              </Type>
              <Group>
                <UppyModal
                  name="portfolioFile"
                  nameUrl="portfolioFileUrl"
                  type="text"
                  pos="left"
                  placeholder="Upload file (.docx / .pdf)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.portfolioFile}
                  valueUrl={values.portfolioFileUrl}
                  setFieldValue={setFieldValue}
                  max={1}
                  filetype={['.pdf', '.doc', '.docx', '.md', '.rtf']}
                />
                <FloatingInput
                  name="portfolioUrl"
                  type="url"
                  pos="right"
                  placeholder="Website"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.portfolioUrl}
                />
              </Group>
              <Type as="h4" align="" size="" spacing="mb-4">
                Recommendation letter* (a minimum of 1 required)
              </Type>
              <Group>
                <UppyModal
                  name="recLetters"
                  nameUrl="recLettersUrl"
                  type="text"
                  placeholder="Upload file (.docx / .pdf)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.recLetters}
                  valueUrl={values.recLettersUrl}
                  setFieldValue={setFieldValue}
                  max={5}
                  filetype={['.pdf', '.doc', '.docx', '.md', '.rtf']}
                />
              </Group>
              <Divider spacing="mt-10 mb-14" />
              {/*  <Submit name="submit" type="submit" value="Send" />
              <button type="submit">Submit</button> */}
            </Type>

            <Results
              validateForm={validateForm}
              setFieldTouched={setFieldTouched}
              isValid={isValid}
            >
              <Box
                width="max-w-full pt-9 sm:pt-30 px-9 sm:px-14"
                flex="flex flex-col items-center"
              >
                <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
                  <Type align="text-center uppercase" size="large">
                    Application Process
                  </Type>
                  <Type align="text-center" size="large">
                    Review and submit application
                  </Type>
                  <Divider spacing="my-14" />
                  <Type>
                    You cannot change or review your application once it is
                    submitted. For any questions or updates, contact us here.
                  </Type>
                  <Divider spacing="my-14" />
                  <Type size="large" spacing="mb-4">
                    1. Scholarship purpose
                  </Type>
                  <Type size="small-serif">
                    <ul>
                      <li>{values.type}</li>
                    </ul>
                  </Type>
                  <Divider spacing="my-14" />
                  <Type size="large" spacing="mb-4">
                    2. Personal Information
                  </Type>
                  <Type size="small-serif">
                    <ul>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">Name: </span>
                        {values.firstName}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Last Name:{' '}
                        </span>
                        {values.lastName}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Date of Birth:{' '}
                        </span>
                        {values.dateOfBirth}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Nationality:{' '}
                        </span>
                        {values.nationality}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Home Address:{' '}
                        </span>
                        {values.address}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Primary Email Address:{' '}
                        </span>
                        {values.email}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Phone Number + Country Code:{' '}
                        </span>
                        {values.phone}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Portrait Picture:{' '}
                        </span>
                        {values.portraitPicture}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Gender Pronouns:{' '}
                        </span>
                        {values.pronouns}
                      </li>
                      <li>
                        <span className="uppercase font-maleesans">
                          Applied Before:{' '}
                        </span>
                        {values.appliedBefore}
                      </li>
                    </ul>
                  </Type>
                  <Divider spacing="my-14" />
                  <Type size="large" spacing="mb-4">
                    3. Tell us more
                  </Type>
                  <Type size="small-serif">
                    <div className="uppercase font-maleesans mb-3">
                      Tell us about yourself. You can write about anything that
                      helps us better understand who you are. This could include
                      and is not limited to: your family, your cultural
                      background, your heritage, your involvement in community,
                      your other passions and interests, significant people,
                      places, or events that have shaped who you are today.
                    </div>
                    <div className="mb-6">
                      {values.autobiographicalStatement}
                    </div>
                    <div className="uppercase font-maleesans mb-3">
                      Tell us about your journey and interest in type design.
                      When did it begin or how did it change over time? Who is
                      part of your story? We do not expect all applicants to
                      come have the same background or experiences in type
                      design.
                    </div>
                    <div className="mb-6">{values.interest}</div>
                    <div className="uppercase font-maleesans mb-3">
                      Why should you be the next recipient of The Malee
                      Scholarship?
                    </div>
                    <div className="mb-6">{values.idealCandidate}</div>
                    <div className="uppercase font-maleesans mb-3">
                      Please explain how the $6,000 USD grant will make a
                      tangible impact on your study, practice, or career in type
                      design. The money can go towards workshops, post-graduate
                      studies, books, software, etc. It can also go towards
                      supporting yourself financially while you work on your
                      typeface. The annual recipient will be gifted a RoboFont
                      license courtesy of the RoboFont team.*
                    </div>
                    <div>{values.plan}</div>
                  </Type>
                  <Divider spacing="my-14" />
                  <Type size="large" spacing="mb-4">
                    4. CV, portfolio, and recommendation letters
                  </Type>
                  <Type size="small-serif">
                    <ul>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          CV File:{' '}
                        </span>
                        {values.cv}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Facebook:{' '}
                        </span>
                        {values.facebook}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Twitter:{' '}
                        </span>
                        {values.twitter}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Instagram:{' '}
                        </span>
                        {values.instagram}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          LinkedIn:{' '}
                        </span>
                        {values.linkedin}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Portfolio:{' '}
                        </span>
                        {values.portfolioFile}
                      </li>
                      <li className="mb-3">
                        <span className="uppercase font-maleesans">
                          Website:{' '}
                        </span>
                        {values.portfolioUrl}
                      </li>
                      <li>
                        <span className="uppercase font-maleesans">
                          Recommendation Letters:{' '}
                        </span>
                        {values.recLetters}
                      </li>
                    </ul>
                  </Type>
                  <Divider spacing="my-14" />
                </Box>
                <Box width="px-6 md:px-16 pb-6 max-w-xl xl:max-w-2xl">
                  <Submit
                    name="submit"
                    type="submit"
                    value="Send"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Submit>
                </Box>
              </Box>
            </Results>

            <Portal>
              <Modal visible={this.state.showModal}>
                <Circle>
                  <p>
                    Thank you for your submission. We will get back to you as
                    soon as possible.
                  </p>
                  <ModalButton onClick={this.closeModal}>Close</ModalButton>
                </Circle>
              </Modal>
            </Portal>

            <Persist name="application2021" />
          </Form>
        )}
      />
    );
  }
}

AppForm.propTypes = {
  data: PropTypes.object,
};

export default AppForm;
